@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;700;900&family=Poppins:wght@200;300;400;600&family=Quicksand:wght@300;400;500;600;700&family=Urbanist:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css");
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Urbanist", sans-serif;
}

html {
    font-size: 62.5%;
    /* scroll-behavior: smooth; */
    /* ya web ma kisi particular section ko dikhata ha */
    scroll-snap-type: Y proximity;
}
section{
    scroll-snap-align: start;
}

.section-navbar {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.container {
    max-width: 142rem;
    margin: 0 auto;
    padding: 9.6rem 2.4rem;
}

:is(.section-about, .section-course, .section-why--choose, .section-contact--homepage, .section-contact) .container:first-child {
    padding: 6.4rem 2.4rem 2.4rem 2.4rem;
}

.section-navbar .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.8rem;
}

.section-navbar .navbar ul {
    display: flex;
    gap: 3.2rem;
    list-style: none;

    /* text-transform: capitalize; */
    & li a {
        color: var(--heading-color);
        text-transform: uppercase;
        display: inline-block;
        position: relative;
        font-size: 1.6rem;

        &::after {
            content: "";
            position: absolute;
            bottom: -2px;
            left: 0;
            border-bottom: .3rem solid var(--main-color);
            width: 0%;
        }

    }

    & li a:hover::after {
        width: 100%;
        animation: lihover .3s linear;
    }
}

@keyframes lihover {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

p {
    font-family: "Urbanist", sans-serif;
    font-size: 1.8rem;
    font-weight: 400;
    letter-spacing: 0.1rem;
    text-decoration: none;
    line-height: 1.5;
    color: var(--para-color);
}

li,
a,
label {
    font-family: "Urbanist", sans-serif;
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: 0.1rem;
    text-decoration: none;
    line-height: 1.5;
    color: var(--para-color);
    list-style: none;
}

:root {
    --main-color: #0062ff;
    --supporting-color: #ebf3fe;
    --font-color: #424242;
    /* --bg-color: #ffffff; */
    --bg-color: #f7fcff;
    --heading-color: #000a19;
    --hero-heading-color: #003b99;
    --white-color: #ffffff;
    --para-color: #504e4d;
    --bnt-hover-bg-color: #003b99;
    --btn-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    --footer-bg-color: #040d12;
}

.section-common--title {
    font-size: 2rem;
    margin: 2.4rem 0 1.2rem 0;
}
.section-navbar .navbar-brand img{
    display: flex;
    /* background-color: #003b99; */
    justify-content: center;
    align-items: flex-start;
}

/***** start blog Section *****/

.section-blog {
    background-color: var(--bg-color);

    & .grid {
        gap: 6.4rem;
    }

    & .blog {
        box-shadow: var(--btn-box-shadow);
        transition: scale .3s linear;

        &:hover {
            scale: 1.1;
        }

        & .blog-content {
            padding: 1.4rem 2.4rem 2.4rem;
        }
    }

    & img {
        width: 100%;
        height: auto;

    }

    & .blog-date {
        display: flex;
        justify-content: space-between;
        margin-top: 1.6rem;
        font-size: 1.4rem;

        & .fa-solid {
            background-color: transparent;
            padding: 0;
        }
    }

    & .section-common--title {
        margin-top: 0.8rem;
    }

}

/***** End blog Section *****/
/***** start main Section *****/
.grid {
    display: grid;
}

.grid-two--cols {
    grid-template-columns: repeat(2, 1fr);
}

.grid-three--cols {
    grid-template-columns: repeat(3, 1fr);
    gap: 9.6rem;
}

.btn {
    display: inline-block;
    padding: 1.2rem 3.2rem;
    background-color: var(--main-color);
    color: var(--white-color);
    border-radius: 0.6rem;
}

.btn:hover {
    background-color: var(--bnt-hover-bg-color);
    cursor: pointer;
    box-shadow: var(--btn-box-shadow);
}

main {
    position: relative;
    background-image: linear-gradient(to top right,
            #3d86fa,
            #4484fb,
            #679eff,
            #b3d2ff,
            #ebf3fe);
}

.section-hero--image img {
    width: 100%;
    height: auto;
    transform: scaleX(-1);
}

.section-hero .grid {
    align-items: center;
    gap: 6.4rem;

    & .hero-subheading {
        text-transform: uppercase;
        letter-spacing: 0.2rem;
        font-size: 1.7rem;
        word-spacing: 0.2rem;
        color: var(--hero-heading-color);
        font-weight: 700;
    }

    & .hero-heading {
        font-size: 5.8rem;
        font-family: "Jost";
        color: var(--hero-heading-color);
        font-weight: 900;
    }

    & .hero-para {
        color: var(--white-color);
        margin: 2rem 0 4.2rem 0;

    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Poppins", sans-serif;
}

.custom-shape-divider-bottom-1715421855 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1715421855 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 12rem;
}

.custom-shape-divider-bottom-1715421855 .shape-fill {
    fill: #FFFFFF;
}

.section-hero .grid {
    padding-bottom: 12rem;
}

/***** End  main Section *****/

/***** Start About Section *****/
.section-common-heading {
    font-size: 3.2rem;
    font-weight: bold;
    text-transform: capitalize;
}

.section-common-subheading {
    color: var(--heading-color);
}

.section-about .about-div img {
    padding: 2.4rem;
    background-color: var(--supporting-color);
    width: 15rem;
    height: auto;
    border-radius: 50%;
    transition: all .3s linear;
}

.section-about .about-div {
    text-align: center;
}

.about-div img:hover {
    background: linear-gradient(to right, #0575e6, #2443b1);
    scale: 1.1;
    rotate: 360deg;


}


/***** End  About Section *****/
/*****  start Course Section *****/
.fa-solid {
    padding: 2.4rem;
    background-color: var(--supporting-color);
    font-size: 2.4rem;
    border-radius: 50%;
    color: var(--hero-heading-color);
}

.grid-four--cols {
    grid-template-columns: repeat(4, 1fr);
    gap: 3.4rem;
}

.section-course .course-div:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    ;
    scale: 1.030;
    rotate: 3.5deg;
    transform-origin: left;
}

.section-course .course-div {
    padding: 3.4rem;
    transition: all .3s linear;
}

.course-div:nth-child(2) .icon .fa-solid {
    color: #68bf9b;
    background-color: #e7f6ef;
}

.course-div:nth-child(3) .icon .fa-solid {
    color: #ff8b52;
    background-color: #fbebe8;
}

.course-div:nth-child(4) .icon .fa-solid {
    color: #183d3d;
    background-color: #ccf7f7;
}

.course-div:nth-child(5) .icon .fa-solid {
    color: #d988b9;
    background-color: rgb(247, 223, 238);
}

.course-div:nth-child(6) .icon .fa-solid {
    color: #ff9b50;
    background-color: #f6dfce;
}

.course-div:nth-child(7) .icon .fa-solid {
    color: #1450a3;
    background-color: #dce9fa;
}

.section-course {
    background-color: var(--bg-color);
}

/***** End Course Section *****/

/***** start Why Choose us *****/
.common-text--highlight {
    width: 6rem;
    aspect-ratio: 1;
    background-color: var(--supporting-color);
    color: var(--main-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.4rem;
    font-weight: 700;

}

.section-why--choose .choose-left--div .why-choose--div {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: start;
    text-align: right;
}

.why-choose--div {
    margin-top: 3.2rem;
}

.choose-center--div img {
    width: 90%;
    height: auto;
}

.choose-center--div,
figure {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.choose-center--div figure:before,
.choose-center--div figure:after {
    /* before sy ak circle aya ha z index sy pix ky pixhy gya and after krny sy circle ky pichy ak or circle aya ha jo border ky liy liya ha,after krny sy wo before ky opr a jy ga */
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--main-color);
    width: 44rem;
    height: 44rem;
    border-radius: 50%;
    z-index: -1;
}

.choose-center--div figure:before {
    animation: circle 5s linear infinite;
}

@keyframes circle {
    0% {
        background-color: #b3d0ff;
    }

    25% {
        background-color: #80b1ff;
    }

    50% {
        background-color: #4d91ff;
    }

    75% {
        background-color: #99c0ff;
    }

    100% {
        background-color: #3381ff;
    }
}

.choose-center--div figure:after {
    background-color: transparent;
    z-index: -2;
    width: 49rem;
    height: 49rem;
    border: 0.5rem solid var(--supporting-color);
}

.choose-center--div figure:hover::after {
    background-color: #156efe;
    transition: all .6s linear;
}

.choose-center--div figure:hover::before {
    background-color: var(--supporting-color);
    animation: change .6s linear;
}

@keyframes change {
    0% {
        background-color: var(--main-color);
    }

    50% {
        background-color: #bfd6f5;
    }

    100% {
        background-color: var(--supporting-color);
    }
}

.section-why--choose .grid-three--cols {
    margin-bottom: -12rem;
}

/***** End Why Choose us *****/

/***** Start Contact home section *****/
.section-contact--homepage {
    width: 60%;
    min-height: 30rem;
    margin: 0 auto;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    /* margin-bottom: 5rem; */
    border-radius: 0.3rem;
    padding: 0 3.2rem;
    position: relative;
    bottom: -15rem;
    background-color: var(--white-color);

    & .grid {
        align-items: center;
        gap: 6.4rem;
    }

    & .contact-title {
        font-size: 3.8rem;
        line-height: 1.2;
    }

    & .contact-content p {
        margin: 1.2rem 0 2.4rem 0;
    }

    & .btn a {
        color: var(--white-color);
    }
}

.section-contact--homepage img {
    width: 90%;
    height: auto;
}

.section-contact--homepage .fa-solid {
    padding: 0 0;
    margin-left: 3px;
    color: var(--white-color);
    background-color: var(--main-color);

}

/***** End  Contact home section *****/
/*****  start Actual contact page  *****/
.mb-3 {
    margin-bottom: 3.2rem;
}

label {
    display: block;
    text-transform: capitalize;
    width: 100%;
}

input,
textarea {
    width: 100%;
    padding: 1.4rem 2.4rem;
    font-size: 1.7rem;
    letter-spacing: 0.1rem;
}

.contact-content .grid {
    gap: 6.4rem;
}

::placeholder {
    font-size: 1.6rem;
    letter-spacing: 0.1rem;
}

.btn-submit {
    font-size: 1.8rem;
    border: none;
    text-transform: capitalize;
}

.section-contact {
    & .grid {
        gap: 6.4rem;
        align-items: center;
    }
}

input:focus-visible,
textarea:focus-visible {
    outline: 0.1rem solid var(--bnt-hover-bg-color);
    border-radius: 3px;
}

/***** End Actual contact page  *****/
/***** Start Footer Section *****/
footer {
    background-color: var(--footer-bg-color);
    padding-top: 15rem;

    & .grid-four--cols {
        width: 70%;
    }
}

footer * {
    color: var(--white-color);
}

.footer-subheading {
    font-size: 2.2rem;
    font-weight: 700;
}

.footer-1--div p {
    margin: 1rem 0 3.2rem 0;
}

.footer-1--div {
    & .fa-brands {
        color: var(--bnt-hover-bg-color);
        background-color: var(--supporting-color);
        width: 5rem;
        aspect-ratio: 1;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .3s linear;

        &:hover {
            color: var(--white-color);
            background-color: var(--bnt-hover-bg-color);
            rotate: 360deg;
        }
    }

    & .social-footer--icons {
        display: flex;
        gap: 2.4rem;
    }
}

.footer-2--div,
.footer-3--div,
.footer-4--div {
    text-align: end;
}

.copyright {
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(1, 1fr);
    border: 2 px solid blue;
    position: relative;
    top: -7rem;
}

/***** End Footer Section *****/
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: var(--supporting-color);
}

::-webkit-scrollbar-thumb {
    background-color: var(--main-color);
    border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: var(--heading-color);
}

/***** start media queries Section *****/
@media (max-width:1400px) {
    html {
        font-size: 56.25%;
    }

    .section-hero img {
        width: 90%;
    }
}

@media (max-width:1220px) {
    html {
        font-size: 50%;
    }

    :is(.section-about, .section-blog, .section-course, .section-contact--homepage, .section-why--choose) .grid {
        gap: 5.4rem;
    }
    .section-navbar .navbar ul li a{
        font-size: 15px;
    }
}

@media (max-width:1100px) {
    .section-course .grid-four--cols {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
    .section-blog .grid-four--cols{
        grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
    }

    .section-why--choose {
        & .choose-center--div {
            & figure::before {
                width: 40rem;
                height: 40rem;
            }

            & figure::after {
                width: 45rem;
                height: 45rem;
            }
        }
    }

}

@media(max-width:998px) {
    .section-hero {
        height: auto;
        padding-bottom: 5rem;

        & .grid-two--cols {
            grid-template-columns: 1fr;

            & .section-hero--content {
                order: 2;
            }

            & .section-hero--image {
                order: 1;

                & img {
                    width: 50%;
                }
            }
        }
    }

    .section-about .about-div {
        text-align: left;

    }

    .section-about .grid {
        gap: 3.4rem;
    }

    footer .grid-four--cols {
        grid-template-columns: repeat(3, minmax(100px, 1fr));

        & .footer-1--div {
            grid-row: 2 / 3;
            grid-column: 1 / -1;
            margin-top: 5.4rem;
        }

        & .footer-2--div,
        .footer-3--div,
        .footer-4--div {
            text-align: left;
        }
    }

    .section-why--choose {
        & .choose-left--div {
            & .why-choose--div {
                align-items: start;
                text-align: left;
            }

        }

        & .choose-left--div {
            order: 2;
        }

        & .choose-right--div {
            order: 3;
        }

        & .choose-center--div {
            order: 1;

            & figure::before {
                width: 0;
                height: 0;
                background-color: transparent;
                padding: 0;
            }

            & figure::after {
                width: 0;
                height: 0;
                background-color: transparent;
                padding: 0;
            }
        }
    }
}

@media (max-width:768px) {

    .grid-two--cols,
    .grid-three--cols {
        grid-template-columns: 1fr;
    }

    .section-navbar .container {
        display: flex;
        flex-direction: column;

        & .navbar-brand {
            text-align: center;
            margin-bottom: 2.4rem;
        }

        & .navbar ul {
            gap: 1.4rem;

            & a {
                font-size: 15px;
            }
        }
    }

    .section-why--choose {
        & .grid-three--cols {
            gap: 1.4rem;
        }

        & img {
            width: 35%;
        }
    }
    main .section-hero .grid .hero-heading{
        font-size: 3.8rem;
    }
    .section-contact--homepage ,btn{
        display: block;
        text-align: center;
    }
    .section-contact--homepage .contact-title{
        font-size: 2.4rem;
    }
    :is( .section-contact--homepage) .container:first-child{
        padding: 6.4rem 0rem 2.4rem 0rem;
    }

}
@media (max-width:576px){
footer .container{
    width: 90%;
    margin-left:40px ;
}
}

/***** End media queries Section *****/